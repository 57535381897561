import videojs from 'video.js/dist/video.min.js';
import SourceMenuItem from './source-menu-item';
const MenuButton = videojs.getComponent('MenuButton');

class SourceMenuButton extends MenuButton{
    player_: any;
   constructor(player, options) {
    super(player, options);
    this.player_ = player;
    const qualityLevels = this.player_.qualityLevels().levels_;
    if (options && options.maxQuality) {
        for (let index = 0; index < qualityLevels.length; index++) {
          qualityLevels[index].enabled = (qualityLevels[index]['height']<=options.maxQuality);
        }
    }
    this.player_.qualityLevels().on(['change', 'addqualitylevel', 'removequalitylevel'], super.update.bind(this));
  }


  /**
   * Create div with videojs classes
   *
   * @returns {videojs.MenuButton} The sum of the two numbers.
   */
  createEl() {
    return videojs.dom.createEl('div', {
      className: 'vjs-http-source-selector vjs-menu-button vjs-menu-button-popup vjs-control vjs-button'
    },{},{});
  }

  /**
   * Create SourceMenuItems and sort them
   *
   * @returns {SourceMenuItem[]} The sum of the two numbers.
   */
  buildCSSClass() {
    return MenuButton.prototype.buildCSSClass.call(this);
  }

  /**
   * Update the menu button
   *
   * @returns {videojs.MenuButton} The updated menu button
   */
  update() {
    return MenuButton.prototype.update.call(this);
  }

  /**
   * Create SourceMenuItems and sort them
   *
   * @returns {SourceMenuItem[]} Sorted array of SourceMenuItems
   */
  createItems() {
    const maxQuality = this.player_.options_.maxQuality;
    const menuItems = [];
    const levels = this.player_.qualityLevels().levels_;
    const selectedIndex = this.player_.qualityLevels().selectedIndex;
    const labels = [];

    for (let index = levels.length - 1; index >= 0; index--) {
      const selected = (index === selectedIndex);
      // Display height if height metadata is provided with the stream, else use bitrate
      let label = `${index}`;
      let sortValue = index;
      const level = levels[index];

      if (level.height) {
        label = `${level.height}p`;
        sortValue = Number.parseInt(level.height, 10);
      } else if (level.bitrate) {
        label = `${Math.floor(level.bitrate / 1e3)} kbps`;
        sortValue = Number.parseInt(level.bitrate, 10);
      }

      // Skip duplicate labels
      if (labels.includes(label)) {
        continue;
      }
      if(level.height<=maxQuality){
        if(this.player_.options_.userSelectedQuality === levels.length){
          level.enabled=true;
        }
        labels.push(label);
        menuItems.push(new SourceMenuItem(this.player_, {label, index, selected, sortValue}));
      }else{
        level.enabled = false;
      }

  }
    // If there are multiple quality levels, offer an 'auto' option
    if (menuItems.length > 1) {
      menuItems.push(new SourceMenuItem(this.player_, { label: 'Auto', index: levels.length, selected: this.player_.options_.userSelectedQuality === levels.length, sortValue: 99_999 }));
    }

    // Sort menu items by their label name with Auto always first
    menuItems.sort((a, b) => {
      return b.options_.sortValue - a.options_.sortValue;
    });
    console.log(selectedIndex)
    console.log(menuItems);
    console.log(this.player_.qualityLevels().levels_);
    return menuItems;
  }

}
export default SourceMenuButton;
